import { create } from 'zustand';
import { Tier } from '@/types';
import { createJSONStorage, persist } from 'zustand/middleware';

interface DealTier {
    dealTier: Tier | null;
    previousDealTier: Tier | null;
    getDealTier: () => Tier | null;
    setDealTier: (tier: Tier) => void;
    setPreviousDealTier: (tier: Tier) => void;
}

export const useDealTierStore = create(
    persist(
        (set, get: () => DealTier): DealTier => ({
            dealTier: null,
            previousDealTier: null,
            getDealTier: () => get().dealTier,
            setDealTier: (tier: Tier) =>
                set((state) => ({
                    previousDealTier: state.dealTier,
                    dealTier: tier,
                })),
            setPreviousDealTier: (tier: Tier) =>
                set(() => ({
                    previousDealTier: tier,
                })),
        }),
        {
            name: 'dealTier',
            storage: createJSONStorage(() => localStorage),
            partialize: (state) =>
                Object.fromEntries(
                    Object.entries(state).filter(([key]) => ['dealTier', 'previousDealTier'].includes(key)),
                ),
        },
    ),
);
