import { z } from 'zod';

export const DriveTrainType = z.ZodEnum.create(['FRONT_WHEEL', 'REAR_WHEEL', 'FOUR_WHEEL']);
export type DriveTrainType = z.infer<typeof DriveTrainType>;

export const driveTrainOptions: Record<DriveTrainType, string> = {
    FRONT_WHEEL: 'Tracción delantera',
    REAR_WHEEL: 'Tracción trasera',
    FOUR_WHEEL: '4x4',
};
