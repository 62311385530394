import { MainLayout } from '@/layouts/MainLayout';
import { isNil } from '@/lib/utils/values';
import { DashboardView } from '@/views/dashboard/DashboardView';
import { ErrorMessage } from '@/views/shared';
import { DashboardViewSkeleton } from '@/views/dashboard/DashboardViewSkeleton';
import { useDashboardPageData } from '@/views/dashboard/useDashboardQuery';
import { useDealTierStore } from '@/stores/useDealTierStore';

function DashboardContent() {
    const { getDealTier } = useDealTierStore();
    const selectedTier = getDealTier();
    const tier = isNil(selectedTier) || ['RENT', 'ALL'].includes(selectedTier) ? 'PAID' : selectedTier;

    const { isLoading, isError, isSuccess, data: DashboardData } = useDashboardPageData({ ...(tier && { tier }) });

    if (isLoading) return <DashboardViewSkeleton />;
    if (isError)
        return (
            <>
                <h1 className="font-semibold text-2xl">Panel principal</h1>
                <ErrorMessage msg="Error al intentar obtener las estadísticas" />
            </>
        );
    if (isSuccess) return <DashboardView data={DashboardData} />;

    return null;
}

export default function Dashboard() {
    return (
        <MainLayout className="max-w-screen p-6">
            <DashboardContent />
        </MainLayout>
    );
}
