import { HTMLAttributes } from 'react';

type ButtonColors =
    | 'light-blue'
    | 'blue'
    | 'white'
    | 'gray'
    | 'light-gray'
    | 'red'
    | 'green'
    | 'purple'
    | 'smoke'
    | 'orange'
    | 'yellow'
    | 'indigo';
type ButtonVariants = 'flat' | 'outline' | 'ghost';
type ButtonSizes = 'xxs' | 'xs' | 'small' | 'base' | 'large' | 'xl';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    color: ButtonColors;
    disabled?: boolean;
    rounded?: boolean;
    size?: ButtonSizes;
    variant?: ButtonVariants;
}

export function Button(props: HTMLAttributes<HTMLButtonElement>) {
    return <button {...props} />;
}
