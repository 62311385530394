import { z } from 'zod';

export enum SortingDirection {
    ASC = 'asc',
    DESC = 'desc',
}

export interface SortTableHeader<T> {
    readonly label: string;
    readonly sortField?: T;
    readonly currentSort?: SortingDirection | null;
    readonly allowSorting: boolean;
    readonly showing: boolean;
}

const sortDirectionTypeEnum: readonly string[] = Object.keys(SortingDirection);
export const SortingDirectionType = z.enum([sortDirectionTypeEnum[0], ...sortDirectionTypeEnum]);
