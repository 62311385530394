import { SortingDirection, SortTableHeader } from '@/lib/shared/Sorting';
import { Button } from '@clidrive/ui';
import { HiArrowNarrowUp, HiArrowNarrowDown } from 'react-icons/hi';
import { LuChevronsUpDown } from 'react-icons/lu';

interface SortButtonProps<T> {
    readonly header: SortTableHeader<T>;
    readonly sortingDirection: SortingDirection | null;
    readonly onChangeHeader: (headers: SortTableHeader<T>) => void;
}

const SortDirectionMap = {
    [SortingDirection.ASC]: SortingDirection.DESC,
    [SortingDirection.DESC]: SortingDirection.ASC,
};

export function SortButton<T>({ header, sortingDirection, onChangeHeader }: SortButtonProps<T>) {
    const handleClick = () => {
        onChangeHeader({
            ...header,
            currentSort: sortingDirection ? SortDirectionMap[sortingDirection] : SortingDirection.DESC,
        });
    };

    return (
        <Button className="flex gap-2 items-center text-gray-400 font-bold mx-auto" onClick={handleClick}>
            <span>{header.label}</span>
            <SortIcon sortingDirection={sortingDirection} />
        </Button>
    );
}

const SortIcon = ({ sortingDirection }: { sortingDirection: SortingDirection | null }) => {
    const className = 'h-5 w-5 my-auto';

    if (sortingDirection === SortingDirection.ASC) {
        return <HiArrowNarrowUp className={className} />;
    } else if (sortingDirection === SortingDirection.DESC) {
        return <HiArrowNarrowDown className={className} />;
    } else {
        return <LuChevronsUpDown className={className} />;
    }
};
