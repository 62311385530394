import { z } from 'zod';
export const notificationTypeEnum = z.enum(['REPRICING', 'AVAILABILITY']);
export const notificationType = {
    REPRICING: 'Repricing',
    AVAILABILITY: 'Disponibilidad',
};
const NotificationHistory = z.object({
    id: z.number(),
    createdAt: z.string().datetime(),
    catalogueVehicle: z.object({
        id: z.number(),
        uuid: z.string(),
        extId: z.string().nullable(),
        licensePlate: z.string().nullable(),
        client: z.object({
            id: z.number(),
            name: z.string(),
            lastName: z.string(),
            phone: z.string().nullable(),
        }),
    }),
    notificationType: notificationTypeEnum,
});
const NotificationHistoryFiltersForm = z.object({
    notificationDateFrom: z.string().datetime().optional(),
    notificationDateTo: z.string().datetime().optional(),
    notificationDateBetween: z.array(z.date()).optional(),
    extId: z.string().optional().nullable(),
    licensePlate: z.string().optional().nullable(),
    notificationType: notificationTypeEnum.optional(),
});
const NotificationHistoryQueryFilters = z.object({
    notificationDateFrom: z.string().datetime().optional(),
    notificationDateTo: z.string().datetime().optional(),
    extId: z.string().optional().nullable(),
    licensePlate: z.string().optional().nullable(),
    notificationType: notificationTypeEnum.optional(),
});
export const GetNotificationHistoryListResponse = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(NotificationHistory).default([]),
    totalItems: z.number(),
});
export const NotificationHistoryFilters = NotificationHistoryQueryFilters.extend({
    max: z.number().optional(),
    offset: z.number().optional(),
});
export type NotificationHistory = z.infer<typeof NotificationHistory>;
export type NotificationHistoryFilters = z.infer<typeof NotificationHistoryFilters>;
export type NotificationHistoryQueryFilters = z.infer<typeof NotificationHistoryQueryFilters>;
export type GetNotificationHistoryListResponse = z.infer<typeof GetNotificationHistoryListResponse>;
export type NotificationHistoryFiltersForm = z.infer<typeof NotificationHistoryFiltersForm>;
