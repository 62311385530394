import { SortingDirectionType } from '@/lib/shared/Sorting';
import { PaginatedListFilters, Tier } from '@/types';
import { LegalNature } from '@/types/Client/options';
import { z } from 'zod';

export enum ClientsSortingField {
    NAME = 'NAME',
    PHONE = 'PHONE',
    EMAIL = 'EMAIL',
    LEGAL_NATURE = 'LEGAL_NATURE',
    DEALS_COUNT = 'DEALS_COUNT',
    CREATED_AT = 'CREATED_AT',
}

const clientSortTypeEnum: readonly string[] = Object.keys(ClientsSortingField);
const ClientSortingType = z.enum([clientSortTypeEnum[0], ...clientSortTypeEnum]);

export const ClientListData = z.object({
    uuid: z.string(),
    name: z.string(),
    lastName: z.string(),
    email: z.string().nullable(),
    phone: z.string().nullable(),
    identificationNumber: z.string().nullable(),
    legalNature: LegalNature.nullable(),
    createdAt: z.string().datetime(),
    dealsCount: z.number().nullable(),
});

export const ClientList = z.object({
    max: z.number(),
    offset: z.number(),
    items: z.array(ClientListData).default([]),
    totalItems: z.number(),
});

export const ClientListFormQueryFilters = z.object({
    createdAtBetween: z.array(z.date()).optional(),
    createdAtFrom: z.string().datetime().optional(),
    createdAtTo: z.string().datetime().optional(),
    email: z.string().optional(),
    phone: z.string().optional(),
    identificationNumber: z.string().optional(),
    legalNature: LegalNature.optional(),
    includeDealsCountByTier: Tier.optional(),
});

const ClientSortParams = z.object({
    sortField: ClientSortingType.optional(),
    sortDirection: SortingDirectionType.optional(),
});

export type ClientList = z.infer<typeof ClientList>;
export type ClientListData = z.infer<typeof ClientListData>;
export type ClientListFormQueryFilters = z.infer<typeof ClientListFormQueryFilters>;
export type ClientListFilters = PaginatedListFilters & ClientListFormQueryFilters & z.infer<typeof ClientSortParams>;
