'use client';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useEffect } from 'react';
import { Router } from 'next/router';

if (typeof window !== 'undefined') {
    const posthogKey = process.env.NEXT_PUBLIC_POSTHOG_KEY;
    if (!posthogKey) {
        throw new Error('PostHog key is not defined');
    }

    const disable_session_recording = process.env.NEXT_PUBLIC_SITE_URL !== 'https://connect.clidrive.com';
    const autocapture = process.env.NEXT_PUBLIC_SITE_URL?.includes('localhost') ? false : true;

    posthog.init(posthogKey, {
        api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
        disable_session_recording,
        autocapture,
        bootstrap: {
            featureFlags: {
                'replace-customers-with-clients': false,
            },
        },
    });
}

interface PHProviderProps {
    readonly children: React.ReactNode;
}

export function PHProvider({ children }: PHProviderProps) {
    const { isLoading, user } = useUser();

    // Handle page-views in SPA
    useEffect(() => {
        const handleRouteChange = () => posthog?.capture('$pageview');
        Router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            Router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, []);

    // Identify by oAuth user
    useEffect(() => {
        if (!posthog._isIdentified()) {
            posthog.identify(user?.sub ?? '', user);
        }
    }, [isLoading, user]);

    return <PostHogProvider client={posthog}>{children}</PostHogProvider>;
}
