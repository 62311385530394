import { AnyObject } from '@/types';

export function objectToQueryString(query: AnyObject) {
    const buildQueryString = (obj: AnyObject, prefix = ''): string => {
        return Object.entries(obj)
            .map(([key, value]) => {
                if (value === undefined) {
                    return '';
                }

                const fullKey = prefix ? `${prefix}.${key}` : key;

                if (typeof value === 'object' && !Array.isArray(value)) {
                    return buildQueryString(value, fullKey);
                }

                if (Array.isArray(value)) {
                    return value.map((v) => `${encodeURIComponent(fullKey)}=${encodeURIComponent(v)}`).join('&');
                }

                return `${encodeURIComponent(fullKey)}=${encodeURIComponent(value)}`;
            })
            .filter((part) => part !== '')
            .join('&');
    };

    return buildQueryString(query);
}

export function queryStringToObject(queryString: string) {
    if (!queryString) return {};

    return queryString
        .replace('?', '')
        .split('&')
        .reduce((acc, curr) => {
            const [key, value] = curr.split('=');
            if (key && value) {
                const keys = key.split('.');
                let temp = acc;
                keys.forEach((k, i) => {
                    if (i === keys.length - 1) {
                        temp[k] = decodeURIComponent(value);
                    } else {
                        temp[k] = temp[k] || {};
                        temp = temp[k];
                    }
                });
            }
            return acc;
        }, {} as any);
}
